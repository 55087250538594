import "../../js/vendor/magnific-popup";
declare let $: any;

export function init() {

	$('body').on('click', '.js-product-modal', function(e) {
		var $href = $(this).attr('href');
		e.preventDefault();
		$('body').addClass('iframe-general');

		// Construct an object for use in populating the modal
		var product = {
			title: $('.product-header h1').text(),
			reviews: $('.product-header .reviews-preview__item--reviews').first().html(),
			finish: $('.product-info__finishes label[for="selectedFinish"]').first().text(),
			image: $('.js-product-image-image').first().attr('src')
		};
		var modalMarkup = `
			<div class="mfp-iframe-scaler">
				<div class="mfp-product-heading">
					<div class="mfp-product-image">
						<img src="${product.image}">
					</div>
					<div class="mfp-product-body">
						<h3 class="mfp-product-body__header">${product.title}</h3>
						<div class="mfp-product-body__finish">
							${product.finish}
						</div>
						<ul class="reviews-preview group">
							<li class="reviews-preview__item reviews-preview__item--reviews">
								${product.reviews === undefined ? "" : product.reviews}
							</li>
						</ul>
					</div>
				</div>
				<div class="mfp-close"></div>
				<iframe class="mfp-iframe padding-left-xs" scrolling="yes" frameborder="0" allowfullscreen></iframe>
			</div>`;

		$.magnificPopup.open({
			items: {
				src: $href
			},
			iframe: {
				markup: modalMarkup

				
			},
			type: 'iframe',
			callbacks: {
				markupParse: function(template, values, item) {
					template.find('iframe').addClass('general-iframe');
				},
				close: function() {
					$('body').removeClass('iframe-general');
				}
			},
			mainClass: 'product-modal modal-zoom'
		});
	})
}