import * as enquire from 'enquire.js';

function mobileMenuInit() {
    // Turn our tabs into a menu at mobile
    enquire.register("screen and (max-width:767px)", {
        match: function() {
            $('.tab-list').each(function(){
                var $tabParent = $(this);
                var $tabList = $tabParent.find('.js-tabs');
                var $activeItem = $tabParent.find('.js-tab.state-selected');
                var activeItemText = $activeItem.text();

                if ($tabList.length > 1) {
                    $tabList.slice(1).remove();
                }

                $(`<a href="#" class="js-tabs-mobile-trigger tab-list__mobile-trigger">${activeItemText}</a>`)
                    .insertBefore($tabList)
                    .wrap("<div class='tab-list__mobile-wrap js-tabs-mobile-wrapper'/>");

                $tabList.appendTo( $tabParent.find('.js-tabs-mobile-wrapper') );
            });

            //setup click events on mobile menu
            registerMobileClickEvents();
        },
        unmatch: function() {
            // Cleanup mobile wrapper / trigger elements
            $('.js-tabs-mobile-wrapper').each(function() {
                $(this).contents().unwrap();
                $(this).remove();
            });

            $('.tab-list').each(function() {
                let $tabList = $(this).find('.js-tabs');

                if ($tabList.length > 1) {
                    $tabList.slice(1).remove();
                }
            });

            $('.js-tabs-mobile-trigger').remove();

            removeMobileClickEvents();
        },
    });
}

function registerMobileClickEvents() {
    $('body').on('click.mobileTabs', '.js-tabs-mobile-trigger', function(e){
        let $parent = $(this).closest('.tab-list__mobile-wrap');
        $parent.find('.js-tabs').toggleClass('state-visible');
        $parent.toggleClass('state-open');
        e.preventDefault();
    });

    $('body').on('click.mobileTabs', '.js-tab', function() {
        let $this = $(this);
        let $parent = $this.closest('.js-tabs-mobile-wrapper');
        $parent.removeClass('state-open');
        $parent.find('.js-tabs').toggleClass('state-visible');

        $parent.find('.js-tabs-mobile-trigger').text( $this.text() );
    });
}

function removeMobileClickEvents() {
    $('body').off('click.mobileTabs').off('click.mobileTabs');
}

export function init() {
    mobileMenuInit();

    $('.js-tab').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);
        let target = $this.data('target');
        let $tabList = $this.closest('.tab-list');
        let $contents = $tabList.find('.js-tab-contents').children();
        let $tabs = $tabList.find('.js-tab');

        $tabs.removeClass('state-selected');
        $this.addClass('state-selected');

        // Trigger a tab change event
        $(this).trigger('tabChange', {
            tab: this,
            target: $this.data('target') || null
        });

        // If there's no data-target for this tab, we're done
        if (!target) { return; }

        $contents.removeClass('state-active');
        let $selectedTab = $contents.filter(target);

        if ($selectedTab.length) {
            $selectedTab.addClass('state-active');
        }
    });
}
