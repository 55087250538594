import * as Tabs from "../modules/tabs";
import * as BrandifyModal from "../modules/brandifyModal";
import "../../js/vendor/magnific-popup";
import * as Swiper from 'swiper/dist/js/swiper.min.js';
import * as enquire from 'enquire.js';
import "objectFitPolyfill/dist/objectFitPolyfill.min";
declare let $: any;
declare var s7viewers: any;
declare let objectFitPolyfill:any;
//import * as Modernizr from "../../js/modernizr/modernizr-3.6.0-dev";

const enum URLs {
    IMAGE_BASE_URL = '//images.kwikset.com/is/image/',
    SCENE7_VIDEO_BASE_URL = '//s7d1.scene7.com/is/content/',
    SKINS_BASE_URL = '//s7d2.scene7.com/skins/',
}
const enum CONFIG {
    DEFAULT_SKIN = 'CONFIG.DEFAULT_SKIN'
}


namespace KWS.controllers.productDetails {
    function setupCarousels(){
        //setup each swiper instance

        if($('.js-kws-product-thumbs-rotator').length) {
            let productsRotator = new Swiper('.js-kws-product-thumbs-rotator', {
                init: false,
                direction: 'vertical',
                slidesPerView: 6,
                spaceBetween: 10,
                preventInteractionOnTransition: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next--product-thumbs',
                    prevEl: '.swiper-button-prev--product-thumbs',
                },

                breakpoints: {
                    768: {
                        direction: 'horizontal'
                    },
                    480: {
                        direction: 'horizontal',
                        slidesPerView: 4,
                        spaceBetween: 10
                    }
                }
            });

            //on init set the current faucet image to the data-img src found on the item in the carousel
            productsRotator.init();
            let $el = $(productsRotator.$el);

            enquire.register("screen and (max-width:767px)", {
                match: function() {
                    $el.removeClass("swiper-container-vertical").addClass("swiper-container-horizontal");
                    productsRotator.update();
                },
                unmatch: function() {
                    $el.removeClass("swiper-container-horizontal").addClass("swiper-container-vertical");
                    productsRotator.update();
                }
            });


        }

        if($('.js-kws-interior-options-rotator').length) {
            let interiorOptionsRotator = new Swiper('.js-kws-interior-options-rotator', {
                init: false,
                slidesPerView: 4,
                spaceBetween: 20,
                preventInteractionOnTransition: true,
                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next--interior-options',
                    prevEl: '.swiper-button-prev--interior-options'
                },
                breakpoints: {
                    // when window width is <= 768px
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    }
                }
            });

            //on init set the current faucet image to the data-img src found on the item in the carousel
            interiorOptionsRotator.init();          
        }

        if($('.js-kws-similar-products-rotator').length) {
            let similarProductsRotator = new Swiper('.js-kws-similar-products-rotator', {
                init: false,
                slidesPerView: 4,
                spaceBetween: 20,
                preventInteractionOnTransition: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next--similar-products',
                    prevEl: '.swiper-button-prev--similar-products'
                },
                breakpoints: {
                    // when window width is <= 768px
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    }
                },
            });

            //on init set the current faucet image to the data-img src found on the item in the carousel
            similarProductsRotator.init();           
        }
        if($('.js-kws-similar-products-rotator1').length) {
            let similarProductsRotator1 = new Swiper('.js-kws-similar-products-rotator1', {
                init: false,
                slidesPerView: 4,
                spaceBetween: 20,
                preventInteractionOnTransition: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next--similar-products1',
                    prevEl: '.swiper-button-prev--similar-products1'
                },
                breakpoints: {
                    // when window width is <= 768px
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    }
                },
            });

            //on init set the current faucet image to the data-img src found on the item in the carousel
            similarProductsRotator1.init();            
        }
        if($('.js-kws-similar-products-rotator2').length) {
            let similarProductsRotator2 = new Swiper('.js-kws-similar-products-rotator2', {
                init: false,
                slidesPerView: 4,
                spaceBetween: 20,
                preventInteractionOnTransition: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next--similar-products2',
                    prevEl: '.swiper-button-prev--similar-products2'
                },
                breakpoints: {
                    // when window width is <= 768px
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    }
                },
            });

            //on init set the current faucet image to the data-img src found on the item in the carousel
            similarProductsRotator2.init();           
        }
        if($('.js-kws-similar-products-rotator3').length) {
            let similarProductsRotator3 = new Swiper('.js-kws-similar-products-rotator3', {
                init: false,
                slidesPerView: 4,
                spaceBetween: 20,
                preventInteractionOnTransition: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next--similar-products3',
                    prevEl: '.swiper-button-prev--similar-products3'
                },
                breakpoints: {
                    // when window width is <= 768px
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    }
                },
            });

            //on init set the current faucet image to the data-img src found on the item in the carousel
            similarProductsRotator3.init();           
        }
        if($('.js-kws-similar-products-rotator4').length) {
            let similarProductsRotator4 = new Swiper('.js-kws-similar-products-rotator4', {
                init: false,
                slidesPerView: 4,
                spaceBetween: 20,
                preventInteractionOnTransition: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next--similar-products4',
                    prevEl: '.swiper-button-prev--similar-products4'
                },
                breakpoints: {
                    // when window width is <= 768px
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    }
                },
            });

            //on init set the current faucet image to the data-img src found on the item in the carousel
            similarProductsRotator4.init();           
        }
    }


    function setupClickEvents() {
        var mainImages = $('.product-images__main-list li'),
            thumbnailImageLinks = $('.js-product-image-link');

        // when a thumbnail is clicked...
        thumbnailImageLinks.on('click', function (e) {
            $('.product-image-thumbnails__item').removeClass('product-image-thumbnails__item--selected');
            e.preventDefault();
            var $this = $(this);
            var clickedLi = $(this).parent();

            if(clickedLi.data('type').includes("image")) {
                var preThumbSrc = $this.find('.js-product-image-image').attr('src');
                var thumbSrc = preThumbSrc.split('?')[0];
                var zoomableSrc = thumbSrc+'?$kwsZoomableImage$';
                var enlargePhotoLink = $('.js-enlarge-photo');
                var primaryImage = $('.js-primary-image');
                clickedLi.addClass('product-image-thumbnails__item--selected');
                enlargePhotoLink.attr('href', zoomableSrc);
                primaryImage.attr('src', zoomableSrc);
                var clickedImg = new Image();
                clickedImg.src = zoomableSrc;

                $('#s7viewer').remove();
                $('#s7basiczoom_div').remove();
                $('<div id="s7basiczoom_div" />').prependTo($(".product-images__main"));
                var s7basiczoom_div = new s7viewers.BasicZoomViewer();
                var idOfElement = clickedLi.data('id');
                var zoomViewerParent = $('#s7basiczoom_div');
                e.preventDefault();
                zoomViewerParent.empty();

                var fullImageUrl = URLs.IMAGE_BASE_URL + idOfElement + '?$kwsZoomableImageLarge$';

                var s7basiczoom_div = new s7viewers.BasicZoomViewer({
                    "containerId": "s7basiczoom_div",
                    "params": {
                        "asset": idOfElement,
                        "serverurl": URLs.IMAGE_BASE_URL,
                        "contenturl": URLs.SKINS_BASE_URL,
                        "config": CONFIG.DEFAULT_SKIN
                    }
                }).init();
            } else if(clickedLi.data('type') === "threesixty") {
               $('#s7basiczoom_div').remove();
                $('#s7viewer').remove();
                $('.embed-responsive').remove();
                $(`<div id="s7viewer" />`).prependTo($(".product-images__main"));
                var spinViewer = new s7viewers.SpinViewer();
                var $this = $(this);
                //$('.icons-list').hide();
                var $theeSixtyID = clickedLi.data('id');
                //$('.swiper-slide').removeClass('swiper-slide-selected');
                $(this).parent('.swiper-slide').addClass('swiper-slide-selected');

                var spinViewerParent = $('#s7viewer');

                e.preventDefault();
                spinViewerParent.empty();

                var spinViewer = new s7viewers.SpinViewer({
                    "containerId":"s7viewer",
                    "params":{
                        "asset": $theeSixtyID,
                        "serverurl": URLs.IMAGE_BASE_URL,
                    }
                }).init();
            }

        });

        $(document).on('mousedown', '.bv_aaq_button', function() {
            // Switch to the QA tab in the Reviews / QA tabset
            $('#toggle-qa').get(0).dispatchEvent(new Event('click'));
        });

        $(document).on('mousedown', '.bv_war_button', function() {
            // Switch to the QA tab in the Reviews / QA tabset
            $('#toggle-reviews').get(0).dispatchEvent(new Event('click'));
        });

        $(document).on('mousedown', '.bv_ratings_summary', function() {
            // Switch to the QA tab in the Reviews / QA tabset
            $('#toggle-reviews').get(0).dispatchEvent(new Event('click'));
        });

        $('.js-product-image-link:first').trigger('click');

        $('.js-enlarge-photo').magnificPopup({
            type: 'image',
            mainClass: 'modal-zoom'
        });

        $('.js-how-to-choose').magnificPopup({
            type:'inline',
            midClick: true,
            mainClass: 'modal-zoom'
        });

        $('.js-why-required').magnificPopup({
            type:'inline',
            midClick: true,
            mainClass: 'modal-zoom'
        });

    }


    export function init() {
        setupClickEvents();
        setupCarousels();
        Tabs.init();
        BrandifyModal.init();
    }

}

KWS.controllers.productDetails.init();
